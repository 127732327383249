// import * as THREE from 'three';
// import { STLLoader } from 'three/examples/jsm/loaders/STLLoader.js';
// import { OBJLoader } from 'three/examples/jsm/loaders/OBJLoader';
// import { MTLLoader } from 'three/examples/jsm/loaders/MTLLoader.js';
// import topCoverUrl from 'url:../public/top-cover.stl'
// import bottomUrl from 'url:../public/bottom.stl'
// import { OrbitControls } from 'three/examples/jsm/controls/OrbitControls.js';
// // import pcbUrl from 'url:../public/3D_PCB_PCB_SmartFusebox_V2_2024-03-27_2024-03-27.obj';
// // import mtlUrl from 'url:../public/3D_PCB_PCB_SmartFusebox_V2_2024-03-27_2024-03-27.mtl';
// import pcbUrl from 'url:../public/pcb.stl';
// import pcbTextureUrl from 'url:../public/pcb.png';
// import TWEEN from '@tweenjs/tween.js'

// const initScene = () => {
//     const scene = new THREE.Scene();
//     const width = 1;
//     const zoom = 0.9;

//     const positions = [
//         [0*zoom, 0*zoom, 150*zoom],
//     ]

//     const renderer = new THREE.WebGLRenderer({ alpha: true, antialias: true }); // Enable transparency
//     const container = document.getElementById('webgl-output')
//     renderer.setSize(container.clientWidth * width, container.clientHeight);
//     //renderer.setSize(window.innerWidth * width, window.innerHeight);
//     container.appendChild(renderer.domElement);
//     //container.style = `width: ${width*100}%`;

//     // scene.background = new THREE.Color('#aaaadd');
//     scene.background = null;
//     const camera = new THREE.PerspectiveCamera(75, container.clientWidth * width / container.clientHeight, 0.1, 300);
//     //const camera = new THREE.PerspectiveCamera(75, window.innerWidth * width / window.innerHeight, 0.1, 1000);
//     camera.position.set(...positions[0]);
//     // camera.rotation.set(90, 90, 0)
//     camera.lookAt(0, 0, 0)
//     // camera.up.set(0, 1, 1)
//     camera.updateProjectionMatrix()

//     const planeGeometry = new THREE.PlaneGeometry(1000000, 1000000); // Adjust size as needed
//     const planeMaterial = new THREE.ShadowMaterial();
//     planeMaterial.opacity = 0.3;
//     const planeMesh = new THREE.Mesh(planeGeometry, planeMaterial);
//     //planeMesh.rotation.x = -Math.PI / 2; // Rotate to make it horizontal
//     planeMesh.receiveShadow = true; // Important for receiving shadows
//     scene.add(planeMesh);

//     const alight = new THREE.AmbientLight( 0xffffff, 0.4); // soft white light
//     scene.add( alight );

//     renderer.shadowMap.enabled = true;
//     renderer.shadowMap.type = THREE.PCFSoftShadowMap; // Optional: for softer shadows

//     // const dirLight = new THREE.DirectionalLight(0xffffff, 1);
//     // dirLight.position.set(50, 100, 50); // Adjust as needed
//     // dirLight.castShadow = true; // Enable casting shadows
//     // dirLight.shadow.mapSize.width = 512; // Adjust for shadow resolution
//     // dirLight.shadow.mapSize.height = 512; // Adjust for shadow resolution
//     // dirLight.shadow.camera.near = 0.5; // Adjust for shadow frustum near plane
//     // dirLight.shadow.camera.far = 500; // Adjust for shadow frustum far plane
//     // dirLight.shadow.camera.left = 100;
//     // dirLight.shadow.camera.right = 100;
//     // dirLight.shadow.camera.top = 100;
//     // dirLight.shadow.camera.bottom = -100;
//     // dirLight.shadow.mapSize.width = 2048; // Higher resolution
//     // dirLight.shadow.mapSize.height = 2048; // Higher resolution

//     // const helper = new THREE.CameraHelper(dirLight.shadow.camera);
//     // scene.add(helper);


//     const dirLight2 = new THREE.DirectionalLight(0xffffff, 1);
//     dirLight2.position.set(-40, -80, 40); // Adjust as needed
//     dirLight2.castShadow = true; // Enable casting shadows
//     dirLight2.shadow.camera.near = 0.5; // Adjust for shadow frustum near plane
//     dirLight2.shadow.camera.far = 500; // Adjust for shadow frustum far plane
//     dirLight2.shadow.camera.left = 100;
//     dirLight2.shadow.camera.right = -100;
//     dirLight2.shadow.camera.top = 100;
//     dirLight2.shadow.camera.bottom = -100;
//     dirLight2.shadow.mapSize.width = 2048; // Higher resolution
//     dirLight2.shadow.mapSize.height = 2048; // Higher resolution

//     scene.add(dirLight2);


//     // Load your STL files
//     const loader = new STLLoader();
//     // const material = new THREE.MeshPhongMaterial({
//     //     color: 0xffffff,
//     //     specular: 0x444444,
//     //     shininess: 600
//     // });
//     const material = new THREE.MeshPhysicalMaterial({
//         color: 0xffffff, // Hexadecimal color
//         emissive: 0x000000, // Emission color, black in this case means no emission
//         roughness: 0.288, // The roughness of the material
//         metalness: 0, // 0 means the material is non-metallic
//         ior: 1.5, // Index of refraction
//         reflectivity: 0.487, // How reflective the material is
//         iridescence: 0, // Iridescence effect, 0 means off
//         iridescenceIOR: 1.3, // Iridescence index of refraction, but it's not active since iridescence is 0
//         sheen: 0, // Sheen effect, 0 means off
//         sheenRoughness: 1, // Sheen roughness, but it's not active since sheen is 0
//         sheenColor: 0x000000, // Sheen color, black in this case means no sheen
//         clearcoat: 0, // Clearcoat layer, 0 means off
//         clearcoatRoughness: 0, // Clearcoat roughness, but it's not active since clearcoat is 0
//         specularIntensity: 1, // Intensity of the specular reflection
//         specularColor: 0xffffff // Color of the specular reflection
//     });

//     // Function to load STL files and return a promise
//     function loadSTL(url, material) {
//         const loader = new STLLoader();
//         return new Promise(resolve => {
//             loader.load(url, geometry => {
//                 const mesh = new THREE.Mesh(geometry, material);
//                 resolve(mesh);
//             });
//         });
//     }

//     // Function to load materials from an MTL file
//     function loadMaterials(url) {
//         return new Promise((resolve, reject) => {
//             const loader = new MTLLoader();
//             loader.load(url, materials => {
//                 materials.preload();
//                 resolve(materials);
//             }, undefined, reject);
//         });
//     }

//     function loadOBJWithMaterials(objUrl, mtlUrl) {
//         return new Promise((resolve, reject) => {
//             loadMaterials(mtlUrl).then(materials => {
//                 const objLoader = new OBJLoader();
//                 objLoader.setMaterials(materials);
//                 objLoader.load(objUrl, object => {
//                     resolve(object);
//                 }, undefined, reject);
//             }).catch(error => {
//                 reject(error);
//             });
//         });
//     }

//     // Function to load an STL file and apply a material to it
//     function loadSTLWithTexture(url, textureUrl) {
//         return new Promise((resolve, reject) => {
//             const loader = new THREE.TextureLoader();
//             loader.load(textureUrl, texture => {
//                 // Adjust texture settings as necessary
//                 texture.wrapS = texture.wrapT = THREE.ClampToEdgeWrapping;
//                 texture.minFilter = THREE.LinearFilter;
//                 texture.magFilter = THREE.LinearFilter;

//                 const material = new THREE.MeshBasicMaterial({ map: texture });
//                 material.color.setRGB(0.4, 0.4, 0.4); // Adjust these values to control the darkening effect
//                 const geometry = new THREE.BoxGeometry(70.2, 114.7, 1);
//                 const mesh = new THREE.Mesh(geometry, material);
//                 texture.colorSpace = THREE.SRGBColorSpace
//                 resolve(mesh);
//                 //     resolve(mesh);
//                 // const stlLoader = new STLLoader();
//                 // stlLoader.load(url, geometry => {
//                 //     const mesh = new THREE.Mesh(geometry, material);
//                 //     resolve(mesh);
//                 // }, undefined, reject);
//             }, undefined, reject);
//         });
//     }


//     // Load both STL files
//     Promise.all([
//         loadSTL(topCoverUrl, material),
//         loadSTL(bottomUrl, material),
//         loadSTLWithTexture(pcbUrl, pcbTextureUrl)
//         //loadOBJWithMaterials(pcbUrl, mtlUrl)
//     ]).then(([topCover, bottom, pcb]) => {
//         // Adjust positions and rotations if necessary
//         topCover.position.set(0, 0, 24);
//         topCover.rotation.set(0, 0, Math.PI);
//         topCover.castShadow = true;
//         //topCover.receiveShadow = true;
//         scene.add(topCover);

//         bottom.position.set(0, 0, 2);
//         bottom.rotation.set(0, 0, Math.PI);
//         bottom.castShadow = true;
//         //bottom.receiveShadow = true;
//         scene.add(bottom);

//         //pcb.scale.set(0.3, 0.3, 0.3);
//         pcb.position.set(0, 0, -14);
//         //pcb.rotation.set(0, 0, 0);
//         pcb.receiveShadow = true;
//         // // After loading the pcb object
//         // pcb.traverse(child => {
//         //     if (child instanceof THREE.Mesh) {
//         //         child.geometry.computeBoundingBox();
//         //         child.geometry.computeVertexNormals();
//         //         child.material.side = THREE.DoubleSide;
//         //         child.material.transparent = false;

//         //         //child.material = new THREE.MeshPhongMaterial({ color: 0xff0000 }); // Example material
//         //     }
//         //});

//         // Assuming pcb is a THREE.Group containing your loaded OBJ meshes
//         let bbox = new THREE.Box3().setFromObject(pcb);

//         // Calculate the center of the bounding box
//         let center = new THREE.Vector3();
//         bbox.getCenter(center);
//         console.log(center)

//         // Offset pcb to center it based on the bounding box
//         // The negative value of the center will move the object to the origin (0,0,0)
//         pcb.position.x = -center.x;
//         pcb.position.y = -center.y;
//         pcb.position.z = -center.z;

//         // Add the pcb to the scene (assuming this hasn't been done yet)
//         scene.add(pcb);
//         pcb.castShadow = true;

//         const animations = [
//             {
//                 start: 0,
//                 end: 500,
//                 animations: [
//                     new TWEEN.Tween(camera.position)
//                     .to({x: positions[0][0], y:  positions[0][1], z: positions[0][2]}, 1000)
//                     .easing(TWEEN.Easing.Cubic.InOut),
//                     new TWEEN.Tween(topCover.position)
//                     .to({x: 0, y: 0, z: 24}, 1000)
//                     .easing(TWEEN.Easing.Cubic.InOut)
//                 ]
//             },
//             {
//                 start: 501,
//                 end: 1000,
//                 animations: [
//                     new TWEEN.Tween(camera.position)
//                     .to({x: 0, y: 0, z: 150*zoom}, 1000)
//                     .easing(TWEEN.Easing.Cubic.InOut),
//                     new TWEEN.Tween(topCover.position)
//                     .to({x: 0, y: 0, z: 400}, 1000)
//                     .easing(TWEEN.Easing.Cubic.InOut)
//                 ]
//             },
//             {
//                 start: 1001,
//                 end: 1500,
//                 animations: [
//                     new TWEEN.Tween(camera.position)
//                     .to({x: 0, y: 0, z: 250}, 1000)
//                     .easing(TWEEN.Easing.Cubic.InOut),
//                     new TWEEN.Tween(topCover.position)
//                     .to({x: 0, y: 0, z: 24}, 1000)
//                     .easing(TWEEN.Easing.Cubic.InOut)
//                 ]
//             },
//         ]

//         let oldScroll = 0;

//         // document.body.addEventListener('scroll', function(e) {
//         document.getElementById('content').addEventListener('scroll', function(e) {
//             const scroll = e.target.scrollTop; 
//             animations.forEach(a => {
//                 if (scroll > a.start && scroll < a.end && (oldScroll < a.start || oldScroll > a.end)) {
//                     a.animations.forEach(aa => aa.start())
//                 }
//             })
//             oldScroll = scroll;
//         });

//         function animate() {
//             requestAnimationFrame(animate);
//             TWEEN.update()
//             //controls.update();
//             camera.lookAt(0, 0, 0)
//             //camera.updateProjectionMatrix();
//             renderer.render(scene, camera);
//         }
        
//         animate();
//     }).catch(error => {
//         console.error('STL loading failed:', error);
//     });

// }

// document.addEventListener('DOMContentLoaded', () => {
//     initScene();
//     document.querySelectorAll('a[href^="#"]').forEach(anchor => {
//         anchor.addEventListener('click', function (e) {
//             e.preventDefault();
            
//             document.querySelector(this.getAttribute('href')).scrollIntoView({
//                 behavior: 'smooth',
//                 block: 'center'
//             });
//         });
//     });
// })
//initScene()

// document.addEventListener('DOMContentLoaded', (event) => {
//     new fullpage('#content', {
//         //options here
//         autoScrolling:true,
//         scrollHorizontally: true
//     });
// })




function resizeBoard() {
    if (document.body.clientHeight > document.body.clientWidth) {
        const sectionHeight = document.querySelectorAll('.section-content')[0].getClientRects()[0].height;
        const boardHeight = document.body.clientHeight - sectionHeight - 60;
        document.getElementById('board').style.height = boardHeight + 'px';
    } else {
        document.getElementById('board').style.height = "";
    }
}

window.addEventListener("resize", resizeBoard);

document.addEventListener('DOMContentLoaded', () => {
    
    resizeBoard();

    document.querySelectorAll('a[href^="#"]').forEach(anchor => {
        anchor.addEventListener('click', function (e) {
            e.preventDefault();
            
            document.querySelector(this.getAttribute('href')).scrollIntoView({
                behavior: 'smooth',
                block: 'center'
            });
        });
    });

    const img1 = document.getElementById('top-board');

    const sections = document.querySelectorAll('.section');

    const observer = new IntersectionObserver((entries, observer) => {
      entries.forEach(entry => {
        if (entry.isIntersecting) {
          console.log(`${entry.target.id || 'Section'} is now visible`);
          if (entry.target.id == 'section2') {
            // window.scrollTo(0, 1);
            img1.classList.add('animate-out');
            img1.classList.remove('animate-in');
          }
          if (entry.target.id == 'section1') {
            img1.classList.add('animate-in');
            img1.classList.remove('animate-out');
          }
          if (entry.target.id == 'section3') {
            img1.classList.add('animate-in');
            img1.classList.remove('animate-out');
          }
        }
      });
    }, {
      root: document.querySelector('#content'),
      rootMargin: '0px',
      threshold: 0.1 // Adjust this value based on when you consider the section 'visible'
    });
  
    sections.forEach(section => {
      observer.observe(section);
    });

    document.getElementById('notify-form').onsubmit = function(event) {
        event.preventDefault();
        document.getElementById('loader').classList.remove('hidden');
        document.getElementById('submit-button').disabled = true;
        fetch('/api/submit-email', {
        //fetch('http://localhost:3000/api/submit-email', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({ email: event.target.email.value })
        })
        .then(response => response.json())
        .then(data => {
            document.getElementById('notify-form').classList.add('hidden');
            document.getElementById('sent').classList.remove('hidden');
        })
        .catch(error => console.error('Error:', error));
    };

    // const modal = document.getElementById('notify-modal-container');
    // modal.addEventListener('click', () => modal.classList.remove('visible'));
    // document.getElementById('notify-modal').addEventListener('click', e => e.stopPropagation());

    // document.querySelectorAll('.open-notify').forEach(e => {
    //     e.addEventListener('click', () => modal.classList.add('visible'));
    // })

})
